.draw {
  animation: draw 10000ms ease-in-out infinite;
  stroke: #d02213;
  padding-top: 7px;
}

@keyframes draw {
  0% {
    stroke-dasharray: 0 400;
    stroke-dashoffset: 200;
    fill-opacity: 1;
    stroke-width: 2;
  }
  37% {
    stroke-dasharray: 200 0;
    stroke-dashoffset: 200;
    fill-opacity: 0;
    stroke-width: 2;
  }
  50% {
    fill-opacity: 1;
    stroke-width: 0;
  }
  100% {
    fill-opacity: 1;
    stroke-width: 0;
  }
}

.animateBox {
  animation: 50s ease 0s normal none infinite running zoomEffect;
  -webkit-animation: 50s ease 0s normal none infinite running zoomEffect;
  -o-animation: 50s ease 0s normal none infinite running zoomEffect;
  -moz--o-animation: 50s ease 0s normal none infinite running zoomEffect;

  @keyframes zoomEffect {
    from {
      transform: scale(1, 1);
    }
    to {
      transform: scale(2, 2);
    }
  }
}
